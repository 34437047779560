@tailwind base;
@tailwind components;
@tailwind utilities;

.font-inter-regular {
  font-family: Inter-Regular;
}

.font-inter-medium {
  font-family: Inter-Medium;
}

.font-inter-light {
  font-family: Inter-Light;
}

.font-inter-bold {
  font-family: Inter-Bold;
}

.font-inter-thin {
  font-family: Inter-Thin;
}

.font-inter-semibold {
  font-family: Inter-SemiBold;
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Regular'), url('../fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Light'), url('../fonts/Inter-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Bold'), url('../fonts/Inter-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-Thin.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@layer components {
  .d-row {
    @apply flex flex-row items-center;
  }
  .d-col {
    @apply flex flex-col;
  }
  .input-light {
    @apply text-xs my-0.5 font-inter-regular rounded-md border border-gray-300 w-full py-2.5 px-3 text-gray-700 focus:outline-none focus:ring-0;
  }
  .input-light-1 {
    @apply text-xs my-0.5 font-inter-regular rounded-md w-full py-2.5 px-3 text-gray-700 focus:outline-none focus:ring-0;
  }
  .input-gray-bg {
    @apply text-md font-inter-regular rounded-xl bg-background w-full py-2 px-3 focus:outline-none h-12;
  }
  .input-gray-bg-border {
    @apply text-md font-inter-regular rounded-xl bg-background w-full py-2 px-3 focus:outline-none h-12 border border-border-gray;
  }
  .input-label {
    @apply text-black text-xs font-inter-regular;
  }
  .input-label-small {
    @apply text-black text-xxs font-inter-regular;
  }
  .heading-medium {
    @apply text-black text-sm font-inter-medium;
  }
  .uppercase-label {
    @apply uppercase text-md;
  }
  .btn-purple {
    @apply py-2 px-4 bg-hh-accent-dark text-sm text-white rounded-md shadow-md hover:text-white outline-none focus:outline-none focus:ring-0 focus:ring-opacity-0 font-inter-regular;
  }
  .btn-purple:hover {
    @apply hover:bg-hh-accent-light;
  }
  .btn-style {
    @apply outline-none focus:outline-none focus:ring-0 focus:ring-opacity-0 text-sm;
  }
  .btn-light {
    @apply py-2 px-4 border-gray-200 border bg-white text-sm text-primary-black rounded-md hover:text-white focus:outline-none focus:ring-0 focus:ring-opacity-0 font-inter-regular;
  }
  .btn-light:hover {
    @apply hover:bg-hh-accent-light;
  }
  .form-control {
    @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none;
  }

  .form-checkbox {
    @apply accent-checkbox-blue focus:accent-checkbox-blue;
  }
}

$primary-color: #6d69d3;
$deep-purple: #373981;
$hh-magenta: #d9328a;
$burnt-orange: #ffa350;
$attention-red: #fa6557;
$main-grey: #98989a;
$background-grey: #f5f5f5;
$header-black: #222;

body {
  font-size: 13px;
  line-height: 1.4;
  background-color: white;
  font-family: 'Inter-Regular';
  color: #2d3958;
}

// Marketing Nav Styles

li > ul {
  transform: translatex(100%) scale(0);
}

li:hover > ul {
  transform: translatex(101%) scale(1);
}

li > button svg {
  transform: rotate(-90deg);
}

li:hover > button svg {
  transform: rotate(-270deg);
}

/* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}

.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}

.scale-0 {
  transform: scale(0);
}

.min-w-32 {
  min-width: 8rem;
}

// Marketing Nav Styles End

.sticky-side-section {
  position: sticky !important;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  top: 115px;
}

.custom-screen {
  min-height: calc(100vh - 200px);
}

.my-tooltip .multi-line {
  text-align: left !important;
  font-size: 12px;
  font-family: Inter-Regular;
}

.place-top,
.place-bottom {
  padding-top: 14px !important;
  padding-bottom: 24px !important;
}

::-webkit-scrollbar {
  display: none;
}

.react-tooltip,
.__react_component_tooltip.show {
  opacity: 1 !important;
  font-family: Inter-Regular;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  text-align: left !important;
  font-size: 12px;
}

.react-datepicker__header {
  background-color: #f7f9fe !important;
}

.react-datepicker__day--selected {
  background-color: #232777 !important;
}

.react-datepicker__day--keyboard-selected {
  color: #000 !important;
  background: transparent !important;
}

.rc-time-picker-panel-select li {
  color: #2d3958 !important;
}

input[type='checkbox'] {
  min-width: 16px !important;
  min-height: 16px !important;
}

strong {
  font-family: Inter-Bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.cards-grid {
  display: grid;
  height: fit-content;
  gap: 10px 4.215%;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 360px);
  grid-auto-rows: minmax(min-content, max-content);
  // justify-content: space-between;
}

@media (max-width: 1150px) {
  .cards-grid {
    display: grid;
    gap: 10px 4.215%;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 340px);
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .cards-grid {
    display: grid;
    gap: 10px 4.215%;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 290px);
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
  }
}

.animate-dot {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}

.pagination {
  margin: 15px 10px 10px 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
  outline: none;
  font-size: 12px;
}

.pagination > .active > a {
  background-color: #5e72e4;
  color: #fff;
  font-size: 14px;
}
.pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: transparent;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: rgba(31, 41, 55, 1);
}
.pagination > li > a:hover {
  background-color: '#373981' !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.previous_page {
  font-size: 14px;
  color: #696868;
}
.previous_page:hover {
  background-color: #373981 !important;
  color: white !important;
}
.next_page {
  font-size: 14px;
  color: #5e72e4 !important;
}

.next_page:hover {
  background-color: #373981 !important;
  color: white !important;
}

.disabled > .next_page {
  background-color: transparent !important;
  color: rgba(31, 41, 55, 0.5) !important;
  cursor: default;
}

.disabled > .previous_page {
  background-color: transparent !important;
  color: rgba(31, 41, 55, 0.5) !important;
  cursor: default;
}

.tags-container {
  display: -webkit-inline-box;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  bottom: 17px;
}

input::placeholder {
  /* Most modern browsers support this now. */
  color: #dee2e6 !important;
}

// .ant-input:hover,
.ant-input-status-error {
  outline: none;
  border-color: #d66d77 !important ;
  --tw-ring-color: transparent !important;
  box-shadow: none !important;
}

.ant-input-status-success {
  // border: 0 !important;
  // border-color: transparent !important;
  // border-width: 0 !important;
  outline: none;
  // border-inline-end-width: 0px !important;
  --tw-ring-color: transparent !important;
  box-shadow: none !important;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

// html {
//   scroll-behavior: smooth;
// }

.address-inputs.ant-input {
  border-width: 0px;
}

.address-inputs.ant-input:hover,
.address-inputs.ant-input:focus {
  border-color: transparent;
  outline: 0px;
}

.address-inputs.ant-input-status-error {
  border-width: 1px !important;
}

.ant-form-item-explain-error {
  color: #d66d77 !important;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 15px;
}

.input-gray-bg-border.ant-input:hover,
.input-gray-bg-border.ant-input:focus {
  border-color: #dee2e6;
  outline: 0px;
}

.icon {
  width: 16px !important;
  height: 16px !important;
}

.small-icon {
  width: 12px !important;
  height: 12px !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

@media (max-width: 1150px) {
  .fc-widget-small,
  .d-hotline.h-btn,
  .hotline-launcher {
    z-index: 89 !important;
  }
}

.enable-scroll {
  overflow: auto !important;
  position: initial !important;
  margin-top: 0 !important;
  width: auto !important;
}
